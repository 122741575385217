var texts = {
  english: {},
  spanish: {
    login: {
      sTitle: 'Inicio de sesión',
      sEmail: 'Correo electrónico',
      sPassword: '••••••••••',
      sLoginButton: 'Iniciar Sesión'
    },
    admin: {
      sideMenu: {
        items: [
          // pickers
          { 
            title: 'Proyectos', 
            icon: 'mdi-note',
            href: '/admin/proyects',
            permission: 'Projects',
          },
          { 
            title: 'Solicitudes de material', 
            icon: 'mdi-hammer-screwdriver',
            href: '/admin/requests',
            permission: 'RequestForMaterials',
          },
          { 
            title: 'Órdenes de compra', 
            icon: 'mdi-text-box-multiple-outline',
            href: '/admin/orders',
            permission: 'PurchaseOrder',
          },
          { 
            title: 'Gestión de O.C.', 
            icon: 'mdi-currency-usd',
            href: '/admin/ocs',
            permission: 'ManagementPurchaseOrders',
          },
          { 
            title: 'Proveedores', 
            icon: 'mdi-account-multiple',
            href: '/admin/providers',
            permission: 'Supplier',
          },
          { 
            title: 'Usuarios', 
            icon: 'mdi-account',
            href: '/admin/users',
            permission: 'PurchaseUser',
          }
          // requests from pickers
        ],
        logout: {
          icon: 'mdi-login',
          text: 'Cerrar sesión',
        }
      },
      proyects: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          {
            text: "Proyecto",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Residente",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Presupuesto(MXN)",
            sortable: false,
            align: "left"
          },
          {
            text: "Estatus",
            sortable: false,
            align: "left",
            width:"150px"
          },
          {
            text: "Fecha de creación",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      ocs: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "ID",
            sortable: false,
            align: "left"
          },
          {
            text: "Proyecto",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Proveedor",
            sortable: false,
            align: "left",
          },
          // date of start
          {
            text: "Monto",
            sortable: false,
            align: "left"
          },
          {
            text: "Estatus",
            sortable: false,
            align: "left",
            width:"200px"
          },
          {
            text: "Acción",
            sortable: false,
            align: "left"
          }
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      orders: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "ID",
            sortable: false,
            align: "left"
          },
          {
            text: "Nombre del proy.",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Fecha de creación",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Proveedor",
            sortable: false,
            align: "left"
          },
          {
            text: "Precio total",
            sortable: false,
            align: "left"
          },
          {
            text: "Estatus",
            sortable: false,
            align: "left",
            width:"150px"

          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran c  itas disponibles.'
      },
      providers: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "Nombre de proveedor",
            sortable: false,
            align: "left"
          },
          {
            text: "Nombre de la empresa",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "RFC",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Correo electrónico ",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "left"
          }
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      requests: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "ID",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Proyecto",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Residente",
            sortable: false,
            align: "left"
          },
          {
            text: "Fecha",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      users: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "#",
            sortable: false,
            align: "left"
          },
          {
            text: "Nombre",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Correo electrónico",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Puesto",
            sortable: false,
            align: "left"
          },
          {
            text: "Órdenes de compra",
            sortable: false,
            align: "left"
          },
          {
            text: "Proyectos",
            sortable: false,
            align: "center"
          },
          {
            text: "Proveedores",
            sortable: false,
            align: "center"
          },
          {
            text: "Material",
            sortable: false,
            align: "center"
          },
          {
            text: "Cotizaciones",
            sortable: false,
            align: "center"
          },
          {
            text: "Órdenes pendientes",
            sortable: false,
            align: "center"
          },
          {
            text: "Acciones",
            sortable: false,
            align: "center"
          },
        ],        
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      delete: {
        // Title for dialog
        sTitle: 'Eliminar',
        // Alert text
        sAlert: 'Estás a punto de eliminar el ',
        // Alert question
        sQuestion: '¿Estás seguro de realizar esta acción?',
        // Cacel button text
        sCancel: 'Cancelar',
        // Accept button text
        sAccept: 'Aceptar'
      }
    },
    resident: {
      sideMenu: {
        items: [
          { 
            title: 'Proyectos', 
            icon: 'mdi-note',
            href: '/admin/proyects'
          },
          { 
            title: 'Solicitudes de material', 
            icon: 'mdi-hammer-screwdriver',
            href: '/admin/requests'
          }
        ],
        logout: {
          icon: 'mdi-login',
          text: 'Cerrar sesión',
        }
      },
      proyects: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          {
            text: "Proyecto",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Residente",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Presupuesto",
            sortable: false,
            align: "left"
          },
          {
            text: "Estatus",
            sortable: false,
            align: "left",
            width:"150px"

          },
          {
            text: "Fecha de creación",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      requests: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "ID",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Proyecto",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Residente",
            sortable: false,
            align: "left"
          },
          {
            text: "Fecha",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      delete: {
        // Title for dialog
        sTitle: 'Eliminar',
        // Alert text
        sAlert: 'Estás a punto de eliminar el ',
        // Alert question
        sQuestion: '¿Estás seguro de realizar esta acción?',
        // Cacel button text
        sCancel: 'Cancelar',
        // Accept button text
        sAccept: 'Aceptar'
      }
    },
    secretary: {
      sideMenu: {
        items: [
          // pickers
          { 
            title: 'Proyectos', 
            icon: 'mdi-note',
            href: '/admin/proyects'
          },
          { 
            title: 'Solicitudes de material', 
            icon: 'mdi-hammer-screwdriver',
            href: '/admin/requests'
          },
          { 
            title: 'Órdenes de compra', 
            icon: 'mdi-text-box-multiple-outline',
            href: '/admin/orders'
          },
          { 
            title: 'Proveedores', 
            icon: 'mdi-account-multiple',
            href: '/admin/providers'
          },
          { 
            title: 'Usuarios', 
            icon: 'mdi-account',
            href: '/admin/users'
          }
          // requests from pickers
        ],
        logout: {
          icon: 'mdi-login',
          text: 'Cerrar sesión',
        }
      },
      proyects: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          {
            text: "Proyecto",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Residente",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Presupuesto",
            sortable: false,
            align: "left"
          },
          {
            text: "Estatus",
            sortable: false,
            align: "left",
            width:"150px"

          },
          {
            text: "Fecha de creación",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      orders: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "ID",
            sortable: false,
            align: "left"
          },
          {
            text: "Nombre del proy.",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Fecha de creación",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Proveedor",
            sortable: false,
            align: "left"
          },
          {
            text: "Precio total",
            sortable: false,
            align: "left"
          },
          {
            text: "Estatus",
            sortable: false,
            align: "left",
            width:"150px"

          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran c  itas disponibles.'
      },
      providers: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "Nombre de proveedor",
            sortable: false,
            align: "left"
          },
          {
            text: "Nombre de la empresa",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "RFC",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Correo electrónico ",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "left"
          }
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      requests: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "ID",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Proyecto",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Residente",
            sortable: false,
            align: "left"
          },
          {
            text: "Fecha",
            sortable: false,
            align: "left"
          },
          {
            text: "Acción",
            sortable: false,
            align: "center"
          },
        ],
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      users: {
        title: 'Citas',
        // search component
        searchTitle: 'Búsqueda general',
        searchPlaceholder: 'Escribe aquí',
        // headers for table
        headers: [
          // id
          {
            text: "#",
            sortable: false,
            align: "left"
          },
          {
            text: "Nombre",
            sortable: false,
            align: "left"
          },
          // name
          {
            text: "Correo electrónico",
            sortable: false,
            align: "left"
          },
          // date of start
          {
            text: "Puesto",
            sortable: false,
            align: "left"
          },
          {
            text: "Órdenes de compra",
            sortable: false,
            align: "left"
          },
          {
            text: "Proyectos",
            sortable: false,
            align: "center"
          },
          {
            text: "Proveedores",
            sortable: false,
            align: "center"
          },
          {
            text: "Material",
            sortable: false,
            align: "center"
          },
          {
            text: "Cotizaciones",
            sortable: false,
            align: "center"
          },
          {
            text: "Órdenes pendientes",
            sortable: false,
            align: "center"
          },
          {
            text: "Acciones",
            sortable: false,
            align: "center"
          },
        ],        
        // no data in table case
        tableNoData: 'No se encuentran citas disponibles.'
      },
      delete: {
        // Title for dialog
        sTitle: 'Eliminar',
        // Alert text
        sAlert: 'Estás a punto de eliminar el ',
        // Alert question
        sQuestion: '¿Estás seguro de realizar esta acción?',
        // Cacel button text
        sCancel: 'Cancelar',
        // Accept button text
        sAccept: 'Aceptar'
      }
    },
  }
}

export default texts