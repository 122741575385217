<template>
  <v-app v-scroll="onScroll">
    <!-- view transition effect -->
    <v-fade-transition mode="out-in">
      <div>
        <main v-bind="attrs">
          <router-view :texts="texts" />
        </main>
      </div>
    </v-fade-transition>
    <!-- view transition effect -->

    <!-- start application's alerts -->
    <v-snackbar
      style="z-index: 9999999999999999999999999999999999999999999999999999"
      v-model="snackbar"
      :color="color"
    >
      <div class="d-flex">
        <span class="mt-2">
          {{ text }}
        </span>
        <v-btn
          :color="btn_text_color"
          text
          @click="snackbar = false"
          v-html="alert_close_text"
        />
      </div>
    </v-snackbar>
    <!-- end application's alerts -->
  </v-app>
</template>

<script>
/**
 *
 * Calling alert system:
 * alerts: this.$store.commit('toggle_alert', {color: '', text: ''})
 *
 * Calling delete in admin
 * this.$store.commit('setDeleteItems', route, id, unit)
 *
 */
export default {
  data: () => ({
    snackbar: false,
    btn_text_color: "white",
    alert_close_text: "Cerrar",
  }),
  methods: {
    onScroll(e) {
      this.$store.commit(
        "set_offsetTop",
        window.pageYOffset || document.documentElement.scrollTop
      );
    },
  },
  watch: {
    // set snackbar alert to toggled
    alert() {
      this.snackbar = this.alert;
    },
    // set VueX state:alert to the current value
    snackbar() {
      this.$store.commit("set_alert", this.snackbar);
    },
    login_status: function() {
      if (this.login_status) {
        this.$router.push("/admin/applications");
      } else {
        this.$router.push("/");
      }
    },
  },
  computed: {
    texts() {
      if (this.login_status) {
        return this.language == 0 ? texts.spanish.admin : texts.english.admin;
      } else {
        return this.language == 0 ? texts.spanish.login : texts.english.login;
      }
    },
    // language
    // 0 == spanish : 1 == english
    language() {
      return this.$store.state.language;
    },
    // returns user login state
    login_status() {
      return this.$store.state.login_in_status;
    },
    // alert variables
    alert() {
      return this.$store.state.alert;
    },
    text() {
      return this.$store.state.text;
    },
    color() {
      return this.$store.state.color;
    },
    // vuetify bug temporary fix
    attrs() {
      return {
        key: this.$route.path,
        style: {
          minHeight: "calc(100vh - " + this.$vuetify.application.top + "px",
        },
      };
    },
  },
};
</script>
<style>
@font-face {
  font-family: LANE;
  src: url("../src/assets/LANENAR_.ttf") format("truetype");
}

.btn-primary-global {
  width: 180px;
  height: 45px !important;
  color: black !important;
  box-shadow: 0px 0px 0px transparent !important;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
}
.btn-secundary-global {
  font-family: "Poppins", sans-serif !important;
  height: 45px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}
.btn-secundary-delete-global {
  height: 45px !important;
  box-shadow: 0px 0px 0px transparent !important;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
}

.global-text-field-date {
  width: 300px;
}

.W-S-nowrap {
  white-space: nowrap !important;
}

* {
  font-family: "Poppins", sans-serif !important;
}
.color-red {
  color: red !important;
}
.DialogRoundedCard {
  border-radius: 30px !important;
}
.DialogTitle {
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  text-align: center;
  font-weight: 600;
}
.DetailsLabel {
  margin-bottom: 0px !important;
  color: #eaa608;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-align: center;
}
.DetailsValue {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-align: center;
}
.v-dialog {
  overflow: hidden;
}
.SearchTitle {
  color: #eaa608;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.ButtonGradient {
  background: transparent linear-gradient(100deg, #eaa608 0%, #f6d20f 100%) 0%
    0% no-repeat padding-box !important;
}
.buttonOption {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin: 15px;
  font-weight: bold;
}
.DialogTextField input::placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #afafaf !important;
  font-size: 12px;
  padding: 10px;
}
.DialogTextField::-moz-placeholder {
  font-family: "Poppins", sans-serif !important;
  color: red !important;
}
.TextFieldTitle {
  color: #eaa608;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.ClosingDialogButton {
  margin-right: 20px;
  margin-top: 20px;
}
.DialogCardHolder {
  background: #ffffff;
  box-shadow: 0px 3px 40px #00000019;
  border-radius: 29px !important;
}
.DialogTextTitle {
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  text-align: center;
  font-weight: 600;
}
.v-dialog {
  --webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.AddSearchVariant {
  margin-top: -35px !important;
}
.addButton {
  margin-top: -45px;
}
.searchTextField input::placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #afafaf !important;
  font-size: 12px;
  padding: 10px;
}
.searchTextField::-moz-placeholder {
  font-family: "Poppins", sans-serif !important;
  color: red !important;
}
th {
  font-family: "Poppins", sans-serif !important;
  color: black !important;
  font-size: 14px !important;
}
.ItemTableText {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
  color: #000000;
  margin: 10px;
}
.maximumWidth {
  width: 100% !important;
}
.maximumHeight {
  height: 110vh !important;
}
/* general styles for application */
.fontPoppins {
  font-family: "Poppins", sans-serif !important;
}

html {
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* 
  General App CSS
*/
.app_alert_text {
  margin: 10px;
  font-family: "Open sans", sans-serif;
}
.v-btn {
  text-transform: none;
}
body {
  background-color: #fafafc;
}

.dp-icon-modal {
  position: absolute !important;
  right: 25px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.dp-title-modal {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 35px;
}

.content-text-loading {
  width: 100%;
}
.text-loading {
  text-align: center;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: grey;
}

.v-data-table__wrapper {
  overflow: auto;
}

.v-data-table__wrapper::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.v-data-table__wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #707070;

  border-radius: 10px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
.cursor-pointer {
  cursor: pointer !important;
}

input::placeholder{
  text-align: start !important;
  font-weight: normal !important;
}
.v-input .v-label {
  height: auto;
}
.icon-information:hover {
  color: red !important;
}
.v-text-field .v-label {
  /* pointer-events: all; */
}

.input-global >>> .v-input__slot {
  /* background-color: #ce0000; */
  box-shadow: 0px 0px 0px transparent !important;
  border-radius: 5px;
  font-size: 14px;
}

.input-global >>> input:-webkit-autofill,
.input-global >>> input:-webkit-autofill:hover,
.input-global >>> input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 0px #f9f9f9 inset !important;
  -webkit-box-shadow: 0 0 0px 30px #f9f9f9 inset !important;
}

.input-global >>> .theme--light.v-label {
  color: #999898;
  border-color: #e5e5e5 !important;
}

/* inputs global style */
.input-global .v-text-field__details {
  display: none;
}

.input-global .v-input__slot {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 0px #85858529 !important;
  border-radius: 9px !important;
  font-size: 13px;
}

.input-global fieldset {
  border: 0px solid #e5e5e5 !important;
}

.input-global input {
  color: #928d8d !important;
  letter-spacing: 0px !important;
}

.text-Poppins {
  font-family: "Poppins", sans-serif !important;
}
.w-145{
  width: 145px !important;
}
.color-grey-global{
  color: #6F7070 !important;
}
.color-red-global {
  color: red !important;
}
.color-green-global{
  color: #AAE4BA !important;
}
.color-green-subtotal-global{
  color: #63e3b2 !important;
}

.content-header-tab-all-global{
  padding-left: 30px;
  padding-right: 30px;
}
.content-tab-all-global{
  background-color: transparent !important;
  padding: 30px;
  /* box-shadow: 0px 3px 40px #00000019 !important; */
}
.content-header-tab-global{
  text-transform: none !important;
  letter-spacing: 0px;
  font-weight: bold;
  min-width: 200px;
}
.v-slide-group__wrapper {
  border: solid 1px white;
  border-bottom-color: #c4c4c4;
  width: auto;
}

.input-filled-global {
  border: 1px #f0f0f0 solid !important;
  border-radius: 10px !important;
}

.input-filled-global input::placeholder {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}

.input-filled-global .v-input__control .v-input__slot {
  background-color: #fafafa !important;
}

/* #region Table Global */
.table-global{
  padding: 15px;
  box-shadow: 0px 3px 40px #00000019 !important;
  border-radius: 20px;
}
.table-global .v-data-table__wrapper{
  min-height: 200px !important;
}
.th-global {
  border-bottom: 3px solid #e4e4e4;
}
.border-table-body {
  border-bottom: 1px solid #e4e4e4;
}
.border-table-footer {
  border-bottom: 2px solid #e4e4e4;
  border-top: 2px solid #e4e4e4;
}
/* #endregion Table Global */
</style>
<style lang="sass">
section
  padding-top: 64px
  padding-bottom: 64px

  @media screen and (max-width: 640px)
    padding-top: 16px
    padding-bottom: 16px

  .theme--dark.v-list-item
    color: #FFFFFF
</style>
