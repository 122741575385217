import Vue from 'vue'
import Router from 'vue-router'

// importing VueX state management for verifying auth 
import store from './store'

// import redirect rules file
import reroute from '../src/reroute'

// import file that manages non-auth user routes
import router_guests from '@/router_guests'
// import file that manages auth user routes
import router_users from '@/router_users'
import VueRouteMiddleware from 'vue-route-middleware';



Vue.use(Router)

// merge route files to a single component to inititate the vue router instance
var allRoutes = []
allRoutes = allRoutes.concat(router_guests, router_users)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRoutes
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

router.beforeEach(VueRouteMiddleware());
// initiate authentication filter for private routes
/*
  "Administrator"
  "PurchaseOrder"
  "Supplier"
  "Projects"
  "RequestForMaterials"
  "PurchaseUser"
  "ManagementPurchaseOrders"
*/
router.beforeEach((to, from, next) => {
  if (to.name == 'PreEntry') {
    if (store.state.login_in_status) {
      next({
        path: '/admin'
      })
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    // if goes to login screen redirect to admin
    if (to.name == 'HomeLogin') {
      if (store.state.login_in_status) {
        next({
          path: '/admin'
        })
      } else {
        next()
      }
    } else {
      // if user is logged in
      if (store.state.login_in_status) {
        // check user permission is administrator
        if (store.state.permissions.includes("Administrator", 0)) {
          next()
        } else {
          // check if user is going to a route he has permission to access
          if (store.state.permissions.includes(to.meta.permission, 0)) {
            next()
          } else {
            // if not redirect user to a route he does have permission to access
            next({
              path: reroute.redirectUser(store.state.permissions)
            })
          }
        }
      } else {
        next({
          path: '/login'
        })
      }
    }
  }
})

export default router
