import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./modules";
import texts from "../src/utils/texts.js";
import lodash from "lodash";
import encode from "./json_encoder.js";
// import VueCurrencyInput from "vue-currency-input";

// const pluginOptions = {
//   /* see config reference */
//   globalOptions: {
//     locale: "en",
//     currency: "USD",
//     valueAsInteger: false,
//     distractionFree: false,
//     precision: 2,
//     autoDecimalMode: true,
//     allowNegative: false,
//   },
// };
// Vue.use(VueCurrencyInput, pluginOptions);

window.encode = encode;
// initiate axios as a winod variable for convinience ( ex: db.get ; db.post ; db.put ; db.delete )
window.db = axios;
// variable for storing texts
window.texts = texts;
// initailize uri for global use

//  window.uri = 'https://api.control3.mx'

window.uri = "https://api.control3.mx";

// window.uri = 'http://192.168.1.20:3000'
// intialize global variable for lodash debounce function
window.lodash = lodash;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
