var reroute = {
  /**
   * 
   * @param {UserPerrmisionArray} perrmissions 
   * 
    "Administrator"
    "PurchaseOrder"
    "Supplier"
    "Projects"
    "RequestForMaterials"
    "PurchaseUser"
    "ManagementPurchaseOrders"
   */
  redirectUser: function (perrmissions) {
    var permission = perrmissions.match(/"([^"]+)"/)[0];
    /*
    Funtion: .replace(/['"]+/g, '')
    Description: Remove double quotes    
    */
    permission = permission.replace(/['"]+/g, '')
    var firstRoute = this.resolveRoutes(permission)
    return firstRoute
  },
  resolveRoutes: function (permission) {
    switch (permission) {
      case 'Administrator':
        return '/admin/'
      case 'PurchaseOrder':
        return '/admin/orders'
      case 'Supplier':
        return '/admin/providers'
      case 'Projects':
        return '/admin/proyects'
      case 'RequestForMaterials':
        return '/admin/requests'
      case 'PurchaseUser':
        return '/admin/users'
      case 'ManagementPurchaseOrders':
        return '/admin/ocs'
    }
  }
}

export default reroute