import Vue from 'vue'
import Vuetify, { VRow, VDataTable } from 'vuetify/lib'

Vue.use(Vuetify, {
  components: { VRow, VDataTable }
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FFCE00',
        lineColor: '#8B834D',
        secondary: '#4FDBD7',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#EBA606',
        fail: '#B71C1C',
        warning: '#FFC107',
        anchor: '#00000A',
        SideMenuLogo: '#000',
        ActiveMenu: '#EAA608'
      }
    }
  },
/*   breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
    },
    scrollBarWidth: 24,
  }, */
})
