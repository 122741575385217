import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import router from "./router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sIdUser: localStorage.getItem("user-id") || "",
    login_in_status: localStorage.getItem("user-tk") !== null ? true : false,
    permissions: localStorage.getItem("user-permissions") || "",
    token: localStorage.getItem("user-tk") || "",
    aAllows: localStorage.getItem("aAllows") || [],
    text: null,
    color: null,
    alert: false,
    language: localStorage.getItem("lang") || 0,
    bAuthentication: false,

    // #region Permissions
    bAdminPermission: false,
    // #endregion Permissions

    offsetTop: 0,
    bSignup: false,
    sIdSupplier: "",
    // drawer states
    bMiniVariant: false,
    // search variable
    sSearch: "",
    dStartDate: null,
    dEndDate: null,
    sArea: null,
    sStatus: null,
    aProject: [],
    aSupplier: [],
    iProjectFilter: 1,
    iMRFilter: 1,
    bHasDebt: false,
    //   --  DELETE COMPONENT  STATES --   //
    bDelete: false,
    sDeleteRoute: "",
    sDeleteId: "",
    sItem: "",
    idItem: 0,
    refresh: false,
    bEditSupplier: false,
    objSupplierToEdit: null,
    msgDelete: "Estás a punto de eliminar un elemento.",
    msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
    bReturn: false,
    sToReturn: "",
    // variables foor specific supplier view
    supplierSelected: {
      sFullName: "",
    },
    //
    totalDebt: 0,
    daysDebt: null,
    aCacheMaterial: [],
    bTabProyectDetail: 0,
  },
  mutations: {
    setIdUser(state, value) {
      state.sIdUser = value;
    },
    setAdminPermission(state, value) {
      state.bAdminPermission = value;
    },
    setIdSupplier(state, value) {
      state.sIdSupplier = value;
    },
    setDebtDays(state, value) {
      state.daysDebt = value;
    },
    setDebt(state, value) {
      state.totalDebt = value;
    },
    setSupplierFullname(state, value) {
      state.supplierSelected.sFullName = value;
    },
    closeEditSupplier(state) {
      state.bEditSupplier = false;
    },
    setEditSupplier(state, item) {
      state.objSupplierToEdit = item;
      state.bEditSupplier = true;
    },
    //   --  DELETE COMPONENT MUTATIONS  --   //
    setDeleteItems(state, item) {
      state.sDeleteId = item.id;
      state.sDeleteRoute = item.route;
      state.sItem = item.unit;
      state.bDelete = true;
      state.idItem = item.idItem;
      state.msgDelete = item.msgDelete;
      state.msgQuestionDelete = item.msgQuestionDelete;
      state.bReturn = item.bReturn;
      state.sToReturn = item.sToReturn;
    },
    setDeleteDialog(state, value) {
      state.bDelete = value;
    },

    // -- SEARCH COMPONENT -- //
    setSearch(state, item) {
      state.sSearch = item;
    },
    setStartDate(state, item) {
      state.dStartDate = item;
    },
    setEndDate(state, item) {
      state.dEndDate = item;
    },
    setArea(state, item) {
      state.sArea = item;
    },
    setStatus(state, item) {
      state.sStatus = item;
    },
    setProject(state, item) {
      state.aProject = item;
    },
    setSupplier(state, item) {
      state.aSupplier = item;
    },
    setHasDebt(state, item) {
      state.bHasDebt = item;
    },
    refresher(state, item) {
      state.refresh = item;
    },
    setIProjectFilter(state, item) {
      state.iProjectFilter = item;
    },
    setIMRFilter(state, item) {
      state.iMRFilter = item;
    },

    // toggle mini state for drawer
    togglebMiniVariant(state) {
      state.bMiniVariant = !state.bMiniVariant;
    },
    set_signup(state, value) {
      state.bSignup = value;
    },
    set_offsetTop(state, value) {
      state.offsetTop = value;
    },
    setTabProyectDetail(state, item) {
      state.bTabProyectDetail = item;
    },
    // changing language
    toggle_language(state, value) {
      state.language = value;
      localStorage.setItem("lang", value);
    },
    // mutations for global alerts
    toggle_alert(state, alert) {
      state.text = alert.text;
      state.color = alert.color;
      state.alert = true;
    },
    set_alert(state, value) {
      state.alert = value;
    },
    // authentication mutations
    // toggle general boolean for auth
    toggle_auth(state, response) {
      state.login_in_status = !state.login_in_status;
      localStorage.setItem("user-tk", response.data.user.sToken);
      state.token = response.data.user.token;
      localStorage.setItem("aAllows", response.data.user.aAllows);
      state.aAllows = response.data.user.aAllows;
      router.push("/admin");
      router.go();
    },
    remove_auth(state) {
      state.login_in_status = !state.login_in_status;
      localStorage.removeItem("user-tk");
    },
    // remove token from local storage
    auth_error(err) {
      localStorage.removeItem("user-tk");
    },
  },
  actions: {
    login({ commit }, user) {
      //const uri = 'https://api.kohesiondental.com'
      //const uri = 'http://ec2-18-220-159-180.us-east-2.compute.amazonaws.com'
      return new Promise((resolve, reject) => {
        var body = new URLSearchParams();
        body.append("sEmail", user.sEmail);
        body.append("sPassword", user.sPassword);
        Axios.post(`${uri}/api/v1/auth/login`, body)
          .then((resp) => {
            localStorage.setItem("user-id", resp.data.user.sUserId);
            localStorage.setItem("permisionUser", resp.data.user.iPositionId);
            localStorage.setItem("mail", resp.data.user.sFullName);
            localStorage.setItem(
              "user-permissions",
              JSON.stringify(resp.data.user.aPermissions)
            );
            /*
              sName: "Administrator"
              sName: "PurchaseOrder"
              sName: "Supplier"
              sName: "Projects"
              sName: "RequestForMaterials"
              sName: "PurchaseUser"
              sName: "ManagementPurchaseOrders"
            */
            commit("toggle_auth", resp);
            resolve(resp);
          })
          .catch((err) => {
            // if the request fails, remove any possible user token if possible
            commit("auth_error", err);
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          // if the request fails, remove any possible user token if possible
          commit("remove_auth");
          localStorage.removeItem("mail");
          localStorage.removeItem("user-permissions");
          resolve({ color: "success", text: "Sesión terminada" });
        } catch (e) {
          reject({
            color: "fail",
            text:
              "Hubo un error terminando la sesíón, favor de intentar de nuevo.",
          });
        }
      });
    },
    getAdminPermission({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          db.get(`${uri}/api/v1/users/${localStorage.getItem("user-id")}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-tk"),
              "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          })
            .then((resp) => {
              let aTemp = resp.data.user.aPermissions.map((e) => {
                return e.sRoleId;
              });

            if (aTemp.includes("d643e40e-b126-4fa9-b452-87346ff446a4") || resp.data.user.bAdminPermission) {
              commit("setAdminPermission", true);
              resolve(true);

            } else {
              commit("setAdminPermission", false);
              resolve(false);
            }

            })
            .catch((err) => {
              this.bLoading = false;
              const alert = {
                color: "red",
                text: err.response.data.message,
              };
              this.$store.commit("toggle_alert", alert);
            });
        } catch (e) {
          reject({
            color: "fail",
            text: "Hubo un error al consultar el permiso administrativo.",
          });
        }
      });
    },
  },
});
