import AdminPermission from "./middleware/AdminPermission";

const routes = [
  {
    /*
     */
    path: "/admin",
    name: "Home",
    redirect: "/admin/proyects",
    meta: {
      requiresAuth: true,
    },
    children: [
      // #region proyect
      {
        path: "proyects",
        name: "Proyects",
        meta: {
          requiresAuth: true,
          permission: "Projects",
        },
        component: () => import("@/views/admin/Proyects"),
      },
      {
        path: "add-proyect",
        name: "AddProyect",
        meta: {
          requiresAuth: true,
          permission: "Projects",
        },
        component: () => import("@/views/admin/AddProyect"),
      },
      {
        path: "proyect/:sProyectId",
        name: "ProyectsDetail",
        meta: {
          requiresAuth: true,
          permission: "Projects",
        },
        component: () => import("@/views/admin/DetailProyect"),
      },
      {
        path: "proyect/:sProyectId/OC/:sOCId",
        name: "ProyectsDetailOC",
        meta: {
          requiresAuth: true,
          permission: "Projects",
          middleware: [AdminPermission],
        },
        component: () => import("@/views/admin/DetailProyectOC"),
      },
      {
        path: "proyects/:sProjectId/category/:sCategorytId",
        name: "Category",
        meta: {
          requiresAuth: true,
          permission: "Projects",
        },
        component: () => import("@/views/admin/DetailCategory"),
      },
      {
        path: "proyects/:sProjectId/category/:sCategorytId/OC/:sOCId",
        name: "ProyectDetailCategoryDetailOC",
        meta: {
          requiresAuth: true,
          permission: "Projects",
          middleware: AdminPermission,
        },
        component: () => import("@/views/admin/ProyectDetailCategoryDetailOC"),
      },
      // #endregion proyect

      {
        path: "requests",
        name: "Requests",
        meta: {
          requiresAuth: true,
          permission: "RequestForMaterials",
        },
        component: () => import("@/views/admin/Requests"),
      },
      // #region Ordenes de compra
      {
        path: "orders",
        name: "Orders",
        meta: {
          requiresAuth: true,
          permission: "PurchaseOrder",
        },
        component: () => import("@/views/admin/Orders"),
      },
      {
        path: "orders/:sOCId",
        name: "OrdersAddOrDetail",
        meta: {
          requiresAuth: true,
          permission: "PurchaseOrder",
          middleware: AdminPermission,
        },
        component: () => import("@/views/admin/AddOrDetailOC"),
      },

      // #endregion Ordenes de compra

      {
        path: "ocs",
        name: "OCs",
        meta: {
          requiresAuth: true,
          permission: "ManagementPurchaseOrders",
        },
        component: () => import("@/views/admin/OCs"),
      },
      {
        path: "ocs/:sOCId",
        name: "DetailsOCGestion",
        meta: {
          requiresAuth: true,
          permission: "ManagementPurchaseOrders",
        },
        component: () => import("@/views/admin/DetailsOCGestion"),
      },

      // #region providers
      {
        path: "providers",
        name: "Providers",
        meta: {
          requiresAuth: true,
          permission: "",
        },
        component: () => import("@/views/admin/Providers"),
      },
      {
        path: "provider/:sSupplierId",
        name: "ProviderDetail",
        meta: {
          requiresAuth: true,
          permission: "Supplier",
        },
        component: () => import("@/views/admin/ProviderDetail"),
      },
      // {
      //   path: "provider/:sSupplierId/OC/:sOCId",
      //   name: "SupplierDetailOC",
      //   meta: {
      //     requiresAuth: true,
      //     permission: "Supplier",
      //     middleware: AdminPermission,
      //   },
      //   component: () => import("@/views/admin/SpecificSupplierOC"),
      // },
      {
        path: "provider/:sIdProvider/OC/:sOCId",
        name: "OCProviderDetail",
        meta: {
          requiresAuth: true,
          permission: "Supplier",
        },
        component: () => import("@/views/admin/OCProviderDetail"),
      },
      // #endregion providers

      // #region Users
      {
        path: "users",
        name: "Users",
        meta: {
          requiresAuth: true,
          permission: "PurchaseUser",
        },
        component: () => import("@/views/admin/Users"),
      },
      {
        path: "users/:sIdUser",
        name: "AddOrEditUser",
        meta: {
          requiresAuth: true,
          permission: "PurchaseUser",
        },
        component: () => import("@/views/admin/AddOrEditUser"),
      },
      // #endregion Users

      {
        path: "*",
        name: "*",
        redirect: "/admin/proyects",
        meta: {
          requiresAuth: true,
        },
      },
    ],
    component: () => import("@/views/Admin"),
  },
];

export default routes;
