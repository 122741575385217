import Vue from "vue";

//#region G L O B A L   C O M P O N E N T S

Vue.component("VTextFiledCurrency", () =>
  import("../components/core/v-text-field-currency.vue")
);



//#endregion G L O B A L   C O M P O N E N T S
